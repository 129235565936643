<template>
    <div class="searchBar" :class="{'focused' : focused || false}">
        <input class="searchBar_input" ref="searchMedia" @click.stop="" v-on:keyup.enter="search" @focus="focused = true" @blur="focused = false">
        <img class="search_action" src='../../../assets/search.svg' @click="search"/>
    </div>
</template>


<script setup>

import { defineEmits, ref } from 'vue';

const searchMedia = ref();
const focused = ref();

const emit = defineEmits(['search'])

function search(){
    emit("search", searchMedia.value.value)
}


</script>

<style lang="scss" scoped>
    .searchBar{
        width: 100%;
        height: 30px;
        
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        background-color: white;
        border: 1px var(--color2) solid;
        transition: border 0.1s;

        &.focused{
            border: 1px var(--color1) solid;

            & > .search_action{
                background-color: var(--color1);
            }
        };

        & > .searchBar_input{
            height: 100%;
            line-height: 30px;
            padding: 0 8px 0 8px;
            border: none;
            width: calc(100% - 38px);
        }


        & > .search_action{
            background-color: var(--color2);
            transition: background-color 0.1s;
            padding: 6px;
            height: 18px;
            width: 18px;
            position: absolute;
            right: 0;
            top : 0;
            cursor: pointer;
        }

        
    }

    

</style>